/* Copyright 2023 (Unpublished) Verto Inc. */

// Angular
import { inject, Injectable } from '@angular/core';
import { Router, Routes } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { ReplaySubject } from 'rxjs';
import { AuthCodeToAccessTokenExchangeComponent } from './components/auth-code-to-access-token-exchange.component';
import { CodeFlowComponent } from './components/code-flow.component';

// Interfaces
import { ModuleConfig, ShellConfig } from './interfaces/shell-config';

@Injectable({
  providedIn: 'root',
})
export class ShellLoader {
  config: ShellConfig;
  configLoaded = new ReplaySubject<void>(1);
  showDebugOverlay = false;

  private _router = inject(Router);

  init(): Promise<any> {
    this._router.resetConfig(this._applicationConfigToRoutes());

    try {
      return this._navigateToInitialRoute();
    } catch (exception) {
      console.error('Attempted to visit initial route that is not defined by application config.');
      throw exception;
    } finally {
      this.configLoaded.next();
    }
  }

  private _applicationConfigToRoutes(): Routes {
    let routes: Routes = [];
    const applicationConfig = this.config.application;
    const moduleConfig = this.moduleConfiguration;

    if (applicationConfig.smartOnFhirConfig) {
      routes = [
        {
          path: `code-flow/${moduleConfig.routerPath}`,
          component: CodeFlowComponent,
        },
        {
          path: `redirect/${moduleConfig.routerPath}`,
          component: AuthCodeToAccessTokenExchangeComponent,
        },
      ];
    }

    // Main route to load application
    routes.push({
      path: moduleConfig.routerPath,
      loadChildren: () =>
        import(`./modules/${moduleConfig.module}`).then((m) => {
          return m[moduleConfig.moduleName];
        }),
    });

    // configure auxilary outlets if the config provides it
    // in most cases this if clause does not execute
    if (moduleConfig.outlet) {
      // todo(aleksanderbodurri): remove auxilary outlet support in the next major version of Engage
      console.warn(
        'auxilary outlets in Engage are deprecated and will be removed in the next major version'
      );
      const mainRoute = routes[0];
      mainRoute.outlet = moduleConfig.outlet;
    }

    return routes;
  }

  private _navigateToInitialRoute(): Promise<boolean> {
    // if this is a smart on fhir application, redirect to code-flow route to begin
    // oauth flow
    if (this.config.application.smartOnFhirConfig) {
      const moduleConfig = this.moduleConfiguration;
      return this._router.navigate(['code-flow', moduleConfig.routerPath], {
        queryParamsHandling: 'preserve',
      });
    }

    const initialRouteConfig = this.initialRoute;

    if (!initialRouteConfig) {
      return;
    }

    const navigatePath = [
      { outlets: { primary: initialRouteConfig.primary, ...initialRouteConfig.auxiliaryOutlets } },
    ];

    return this._router.navigate(navigatePath, { queryParamsHandling: 'preserve' });
  }

  get moduleConfiguration(): ModuleConfig {
    if (this.moduleConfigurations.length > 1) {
      throw new Error('Loading multiple modules in Engage is deprecated.');
    }

    return this.moduleConfigurations[0];
  }

  private get moduleConfigurations(): ModuleConfig[] {
    if (!this.config) {
      return [];
    }

    return this.config.application.moduleConfigurations;
  }

  get initialRoute() {
    if (!this.config) {
      return;
    }

    return this.config.application.initialRoute;
  }

  get outlets(): string[] {
    return this.moduleConfigurations.map((moduleConfig) => moduleConfig.outlet);
  }

  get debug(): boolean {
    const v1Location = this.config?.implementation?.extraInfo?.content?.debug;
    const v2Location = this.config?.content?.debug;

    return v2Location ?? v1Location ?? false;
  }

  set debug(val: boolean) {
    const v1Location = this.config?.implementation?.extraInfo?.content;
    const v2Location = this.config?.content;

    (v2Location ?? v1Location).debug = val;
  }

  get debugOverlayEnabled(): boolean {
    return this.debug && this.showDebugOverlay;
  }

  get progressBarEnabled(): boolean {
    const v1Location = this.config?.implementation?.extraInfo?.content?.progressBarEnabled;
    const v2Location = this.config?.content?.progressBarEnabled;

    return v2Location ?? v1Location ?? false;
  }

  get initialState() {
    const v1Location = this.config?.implementation?.extraInfo?.content?.machine?.initial;
    const v2Location = this.config?.content?.machine?.initial;

    return v2Location ?? v1Location ?? '';
  }

  get enableAvailability() {
    const v1Location = this.config?.implementation?.extraInfo?.enableAvailability;

    return v1Location ?? false;
  }

  get content() {
    const v1Location = this.config?.implementation?.extraInfo?.content;
    const v2Location = this.config?.content;

    // use v1 over v2 to account for edge case where we use both (ie. embedding openclinic widget within chl-patient-portal)
    return v1Location ?? v2Location ?? {};
  }

  get dropdownItems() {
    const v1Location = this.config?.implementation?.extraInfo?.dropdownItems;
    const v2Location = this.config?.dropdownItems;

    return v2Location ?? v1Location ?? {};
  }

  get locales() {
    const v1Location = this.config?.implementation?.extraInfo?.locales;
    const v2Location = this.config?.locales;

    return v2Location ?? v1Location ?? {};
  }

  get contextMappings() {
    const v1Location = this.config?.implementation?.extraInfo?.contextMappings;
    const v2Location = this.config?.contextMappings;

    return v2Location ?? v1Location ?? {};
  }

  get services() {
    const v1Location = this.config?.implementation?.extraInfo?.services;
    const v2Location = this.config?.services;

    return v2Location ?? v1Location ?? {};
  }

  get mode() {
    const v1Location = this.config?.implementation?.extraInfo?.mode;
    const v2Location = this.config?.mode;

    return v2Location ?? v1Location ?? '';
  }

  get logo() {
    const v1Location = this.config?.implementation?.extraInfo?.logo;
    const v2Location = this.config?.logo;

    return v2Location ?? v1Location ?? '';
  }

  get googleMapConfigs() {
    const v1Location = this.config?.implementation?.extraInfo?.googleMapConfigs;
    const v2Location = this.config?.googleMapConfigs;

    return v2Location ?? v1Location ?? {};
  }

  get daysInAdvance() {
    const v1Location = this.config?.implementation?.extraInfo?.daysInAdvance;
    const v2Location = this.config?.daysInAdvance;

    return v2Location ?? v1Location ?? 7;
  }

  get engageUrl() {
    const v1Location = this.config?.implementation?.extraInfo?.engageUrl;
    const v2Location = this.config?.engageUrl;

    return v2Location ?? v1Location ?? {};
  }

  get googleApiKey() {
    const v1Location = this.config?.implementation?.extraInfo?.googleApiKey;
    const v2Location = this.config?.googleApiKey;

    return v2Location ?? v1Location ?? '';
  }

  get unitsUrl() {
    const v1Location = this.config?.implementation?.extraInfo?.unitsUrl;
    const v2Location = this.config?.unitsUrl;

    return v2Location ?? v1Location ?? '';
  }

  get serviceTypeUrl() {
    const v1Location = this.config?.implementation?.extraInfo?.serviceTypeUrl;
    const v2Location = this.config?.serviceTypeUrl;

    return v2Location ?? v1Location ?? '';
  }

  get backendUrl() {
    const v1Location = this.config?.implementation?.extraInfo?.backendUrl;
    const v2Location = this.config?.content.backendUrl;

    return v1Location ?? v2Location ?? '';
  }

  get contentMode() {
    return this.config?.content?.mode ?? '';
  }
}
